// extracted by mini-css-extract-plugin
export var mvpWebDevBenefitsSection = "K_gL";
export var mvpWebDevBusinessTypeSection = "K_gH";
export var mvpWebDevCaseSection = "K_gP";
export var mvpWebDevClientQuotes = "K_gQ";
export var mvpWebDevExpertiseSection = "K_gN";
export var mvpWebDevIndustriesSection = "K_gG";
export var mvpWebDevPracticesSection = "K_gM";
export var mvpWebDevPrimeSection = "K_gD";
export var mvpWebDevProcessSection = "K_gJ";
export var mvpWebDevServicesSection = "K_gF";
export var mvpWebDevTechStackSection = "K_gK";